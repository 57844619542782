import React, { useEffect } from "react";
import "../styles/free_day_pass/success.scss";
import Button from "../components/global/Button";
import SuccessCard from "../components/global/SuccessCard";
import { JOURNEY_COLOURS, JourneyType } from "../shared/Theme";
import { getPaymentStatus, postMessageToParent } from "../shared/Client";
import Card from "../components/global/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faApple } from "@fortawesome/free-brands-svg-icons";
import LoadSuccessPageAnalytics from "../components/global/LoadSuccessPageAnalytics";
import { ReactComponent as AndoridTv } from "../svg/andriod_tv.svg";
import { ReactComponent as Andorid } from "../svg/andorid.svg";
import { ReactComponent as FireTv } from "../svg/fire_tv.svg";
import { ReactComponent as AppleTv } from "../svg/apple_tv.svg";
import { FRONTEND_BASE_URL } from "../shared/constants";
import { trackUserOption } from "../utils/tracking";
import { parseSearch } from "../shared/helpers";
import { useHistory } from "react-router-dom";
import { DiscountPlan } from "../shared/Models";

export type SuccessProps = {
  journey: JourneyType;
};

export type LiveProgramme = {
  title: string;
  subtitle: string;
  image_url: string;
  video_subtype: string;
  video_id: number | null;
};

const scriptsToLoad = [
  // <!-- Event snippet for RTV Price Discount conversion page -->
  {
    type: "discounted",
    content: `gtag('event', 'conversion', {
      'send_to': 'AW-447144387/-3L-CP7wvbAaEMPDm9UB',
      'value': 1.0,
      'currency': 'GBP' });`
  },
  // <!-- Event snippet for RTV Free Trial conversion page -->
  {
    type: "free_trial",
    content: `gtag('event', 'conversion', {
        'send_to': 'AW-447144387/7UE7CKG3wLAaEMPDm9UB',
        'value': 1.0,
        'currency': 'GBP'});`
  },
  // <!-- Twitter conversion tracking event code -->
  {
    type: "discounted",
    content: `twq('event', 'tw-o10hw-pdnpp', {});`
  },
  {
    type: "free_trial",
    content: `twq('event', 'tw-o10hw-pdnsn', {});`
  }
];

const Success: React.FC<SuccessProps> = ({ journey }) => {
  const history = useHistory();

  useEffect(() => {
    const { plan } = parseSearch(history.location.search, "plan");
    if (plan && plan === "apple_day_pass") {
      console.log("[SUCCESS]::getPaymentStatus: ios_paid");
      trackUserOption({
        event: "subscription_completed",
        join_option_selected: { plan_name: plan || "unknown" }
      });
      postMessageToParent({
        action: "payment_complete",
        event: "subscription_completed",
        planName: plan || "unknown"
      });
    } else {
      getPaymentStatus().then((res) => {
        console.log("[SUCCESS]::getPaymentStatus:", res);
        const { cart } = res;
        if (cart?.payment_status === "completed") {
          trackUserOption({
            event: "subscription_completed",
            join_option_selected: { plan_name: cart?.plan_name || "unknown" }
          });
          postMessageToParent({
            logEvent: { key: `sign-up-modal-page-four-subscribe-success`, data: {} }
          });

          try {
            // fire scripts
            const discountedPlans = sessionStorage.getItem("discountedPlans");
            const parsedDiscountedPlans = discountedPlans ? JSON.parse(discountedPlans) : [];
            if (parsedDiscountedPlans?.length) {
              const userHasSelectedDiscountedPlan = parsedDiscountedPlans?.find(
                (plan: DiscountPlan) =>
                  plan?.plan_normal?.toLowerCase() === cart?.plan_name?.toLowerCase()
              );

              if (userHasSelectedDiscountedPlan) {
                const isFreeTrialPlan = userHasSelectedDiscountedPlan?.plan_normal
                  ?.toLowerCase()
                  ?.includes("free trial");

                const scripts = scriptsToLoad.filter(
                  (s) => s.type === (isFreeTrialPlan ? "free_trial" : "discounted")
                );

                scripts?.forEach((s, i) => {
                  const script = document.createElement("script");
                  script.innerHTML = s.content;
                  script.type = "text/javascript";
                  script.id = `script-${i}-${s.type}`;
                  document.body.appendChild(script);
                });
              }
            }
          } catch (e) {
            console.error("[AdTracking] Error parsing discounted plans: ", e);
          }
        }
      });
    }
  }, []);

  return (
    <>
      {LoadSuccessPageAnalytics()}
      <SuccessCard journey={journey} />
      <div className="center" style={{ marginTop: 10 }}>
        <Button
          type="submit"
          text="Watch Now"
          size="medium"
          color={JOURNEY_COLOURS[journey]}
          rightComponent={null}
          onClick={() =>
            postMessageToParent(
              { signup_complete: true, jwt_token: sessionStorage.getItem("memberToken") },
              FRONTEND_BASE_URL
            )
          }
        />
      </div>
      <Card
        header="...Or on the go"
        body={
          <div className="flex" style={{ marginTop: 15 }}>
            <a
              style={{ marginRight: 10 }}
              target="_blank"
              href="https://itunes.apple.com/gb/app/racing-uk-live-horse-racing/id352776768?mt=8">
              <FontAwesomeIcon icon={faApple} />
            </a>
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.racinguk.app">
              <Andorid className="icon" />
            </a>
          </div>
        }
        border={false}
      />
      <br />
      <Card
        header="...Or on the big screen"
        body={
          <div style={{ marginTop: 10, marginLeft: 22 }}>
            <a
              target="_blank"
              href="https://www.racingtv.com/largescreenapp"
              style={{ marginRight: 10 }}>
              <AppleTv className="icon" />
            </a>
            <a
              style={{ marginRight: 10 }}
              target="_blank"
              href="https://www.racingtv.com/largescreenapp">
              <FireTv className="icon" />
            </a>
            <a target="_blank" href="https://www.android.com/tv/">
              <AndoridTv className="tv-icon" />
            </a>
          </div>
        }
        border={false}
      />
    </>
  );
};

export default Success;
